import { Link, Text, Tooltip, VStack } from "@chakra-ui/react"
import {
  NavigationMegaMenuCellEntityHydrated,
  PageEntity,
} from "@jackfruit/common"
import { useResourceList } from "hooks/useResourceList"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

interface RouteParams {
  appId: string
}

interface Props {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuPreviewCellLinks: React.FC<Props> = ({ cell }) => {
  const { links } = cell
  const { appId } = useParams<RouteParams>()

  const [pageQuery] = useState({
    query: {
      appId,
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  const {
    data: { data: pages = [] },
  } = useResourceList<PageEntity>("pages", pageQuery)

  return (
    <VStack alignItems="flex-start" spacing={2}>
      {links.map(link => {
        const page = pages.find(page => page.id === link.pageId)
        const linkPath = link.path || "not set"
        const linkLabel = page ? `[PAGE]: ${page.name}` : `[URL]: ${linkPath}`
        const toolTipColor = page ? "primary.400" : "gray.600"
        const isDeadLink = !page && linkPath === "not set"

        return (
          <Link key={link.id}>
            <Tooltip label={linkLabel} placement="auto" bg={toolTipColor}>
              <Text key={link.id} color={isDeadLink ? "red.300" : "gray.600"}>
                {link.label}
              </Text>
            </Tooltip>
          </Link>
        )
      })}
    </VStack>
  )
}

export default MegaMenuPreviewCellLinks
