import { FormControl, Input, InputGroup } from "@chakra-ui/react"
import { BlockRichTextEntity } from "@jackfruit/common"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import HtmlEditButton from "quill-html-edit-button"
import ImageUploader from "quill-image-uploader"
import BlotFormatter from "quill-blot-formatter"
import React from "react"
import { useForm } from "react-hook-form"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./BlockRichTextForm.scss"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import api from "services/api"
import { IDAttribute } from "quill/id-attribute"
import { TypeAttribute } from "quill/type-attribute"

Quill.register("modules/blotFormatter", BlotFormatter)
Quill.register("modules/htmlEditButton", HtmlEditButton)
Quill.register("modules/imageUploader", ImageUploader)
Quill.register({ "attributors/attribute/id": IDAttribute }, true)
Quill.register({ "attributors/attribute/type": TypeAttribute }, true)
Quill.register({ "formats/id": IDAttribute }, true)
Quill.register({ "formats/list": TypeAttribute }, true)

interface Props {
  entity: BlockRichTextEntity
  isLoading: boolean
  onSubmit: (data: BlockRichTextEntity) => void
}

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        {
          align: [],
        },
      ],
      ["link", "image"],
      ["clean"],
    ],
  },
  imageUploader: {
    upload: async (file: File) => {
      const assetEntity = await api.uploadOneFile(file)
      return assetEntity.path
    },
  },
  blotFormatter: {},
  htmlEditButton: {
    buttonHTML: `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-code">
        <polyline points="16 18 22 12 16 6"></polyline>
        <polyline points="8 6 2 12 8 18"></polyline>
      </svg>`,
  },
}

const BlockRichTextForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
    html: entity.html?.replace(/style="[^"]*"/g, ""),
  }

  const { handleSubmit, register, setValue, watch } =
    useForm<BlockRichTextEntity>({
      defaultValues,
    })

  const handleAutomaticIds = (data: BlockRichTextEntity) => {
    const div = document.createElement("div")
    div.innerHTML = data.html.trim().replace(/style="[^"]*"/g, "")

    const headings = div.querySelectorAll("h1, h2, h3")

    for (const heading of headings) {
      const id = (heading.textContent || "")
        .toLowerCase()
        .replace(/(^[^a-z]+)|([^a-z0-9]+)/g, "-")
        .replace(/^-+|-+$/g, "")
      heading.setAttribute("id", id)
    }

    data.html = div.innerHTML

    return onSubmit(data)
  }

  return (
    <FormContainer onSubmit={handleSubmit(handleAutomaticIds)}>
      <FormControl>
        <InputGroup>
          <Input ref={register()} name="html" type="hidden" />
        </InputGroup>
        <ReactQuill
          theme="snow"
          className="rich-text-content"
          style={{
            height: "600px",
            marginBottom: "50px",
          }}
          modules={modules}
          value={watch("html")}
          onChange={value => setValue("html", value)}
        />
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default BlockRichTextForm
